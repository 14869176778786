import { Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { HarnessFacade } from '@app/+state/feature-flags/harness.facade';
import {
  Evaluation,
  Event,
  initialize,
  Options,
  Result,
  Target,
  VariationValue,
} from '@harnessio/ff-javascript-client-sdk';
import { FeatureFlagTarget, FlagName, HarnessIdentifier } from '@ra-state';
import { LoggerService } from './logger.service';

export type FlagScope = 'Service-Flags' | 'Common-Service';

export const SERVICE_FLAGS_HARNESS = new InjectionToken<HarnessService>('SERVICE_FLAGS_HARNESS');
export const SERVICE_HARNESS_KEY = new InjectionToken<string>('SERVICE_HARNESS_KEY');
export const CS_HARNESS_KEY = new InjectionToken<string>('CS_HARNESS_KEY');
export type HarnessInitializer = (apiKey: string, target: Target, options?: Options) => Result;
@Injectable({
  providedIn: 'root',
})
export class HarnessService implements OnDestroy {
  changeFeedMap = new Map<HarnessIdentifier, Result>();

  initialize = initialize;
  HARNESS_KEY: string;
  scope: FlagScope;

  initializeTarget(featureFlagsTarget: FeatureFlagTarget): void {
    this.logger.log('Initializing feature flags with target: ', featureFlagsTarget);
    const initResult = this.initialize(this.HARNESS_KEY, featureFlagsTarget as Target);
    initResult.on(Event.ERROR, (err) => {
      this.logger.warn('Unknown failure in feature SDK. All experimental features disabled.', err);
      this.logger.error('Feature flag service failed');
    });
    initResult.on(Event.READY, this.onReadyCallback);
    initResult.on(Event.CHANGED, this.onChangeCallback);
    this.changeFeedMap.set(featureFlagsTarget.identifier, initResult);
  }

  closeChangeFeed(identifier: HarnessIdentifier): void {
    this.changeFeedMap.get(identifier)?.close();
    this.changeFeedMap.delete(identifier);
  }

  constructor(
    private readonly harnessFacade: HarnessFacade,
    private readonly logger: LoggerService,
    API_KEY: string,
    scope?: FlagScope,
  ) {
    this.scope = scope ?? 'Common-Service';
    this.HARNESS_KEY = API_KEY;
    this.logger = this.logger.withContext(`HarnessService.${this.scope}`);
  }

  onReadyCallback: (flags: Record<string, VariationValue>) => void = (flags: Record<string, VariationValue>) => {
    this.harnessFacade.setFeatureFlags(flags, this.scope);
  };

  onChangeCallback: (change: Evaluation) => void = (change: Evaluation) => {
    if (change.deleted) {
      this.logger.log('Deleting feature flag', change.flag);
      this.harnessFacade.deleteFeatureFlag(change.flag as FlagName, this.scope);
    } else {
      this.logger.log('Updating feature flag:', change.flag, change.value);
      this.harnessFacade.updateFeatureFlag(
        {
          flag: change.flag as FlagName,
          value: change.value,
        },
        this.scope,
      );
    }
  };
  ngOnDestroy(): void {
    this.changeFeedMap.forEach((value) => value.close());
  }
}

export class HarnessServiceError extends Error {}
