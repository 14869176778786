import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FlagScope } from '@servicesV2/harness.service';
import { AppState, FeatureFlag, FeatureFlagUpdate, FlagName } from '../lemans-app.model';
import { deleteFeatureFlag, setFeatureFlags, updateFeatureFlag } from './feature-flags.actions';

@Injectable({
  providedIn: 'root',
})
/*
  This facade should only be used by harness service to react to harness service events
*/
export class HarnessFacade {
  constructor(private store$: Store<AppState>) {}

  setFeatureFlags(flags: FeatureFlag, scope: FlagScope): void {
    this.store$.dispatch(setFeatureFlags({ scope: scope, flags: flags }));
  }

  updateFeatureFlag(update: FeatureFlagUpdate, scope: FlagScope): void {
    this.store$.dispatch(updateFeatureFlag({ scope: scope, payload: update }));
  }

  deleteFeatureFlag(flag: FlagName, scope: FlagScope): void {
    this.store$.dispatch(deleteFeatureFlag({ scope: scope, payload: flag }));
  }
}
