import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';
import { HarnessFacade } from '@app/+state/feature-flags/harness.facade';
import { ENV_VARS } from '@app/models/config.model';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/components';
import { DataService } from '@servicesV2/data.service';
import {
  CS_HARNESS_KEY,
  HarnessService,
  SERVICE_FLAGS_HARNESS,
  SERVICE_HARNESS_KEY,
} from '@servicesV2/harness.service';
import { LoggerService } from '@servicesV2/logger.service';
import { EnsureModuleLoadedOnceGuard } from './guards/ensure-module-loaded-once.guard';
import { ModalModule } from './modal/modal.module';
import { SnackbarModule } from './snackbar/snackbar.module';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [SpinnerComponent],
  exports: [ModalModule, SnackbarModule, SpinnerComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    ModalModule,
    SnackbarModule,
    MatProgressSpinnerModule,
    RaUiLoadingSpinnerModule,
  ],
  providers: [
    DataService,
    {
      provide: HarnessService,
      useFactory: (harnessFacade, logger, apiKey): HarnessService => new HarnessService(harnessFacade, logger, apiKey),
      deps: [HarnessFacade, LoggerService, CS_HARNESS_KEY],
    },
    {
      provide: SERVICE_FLAGS_HARNESS,
      useFactory: (harnessFacade, logger, apiKey): HarnessService =>
        new HarnessService(harnessFacade, logger, apiKey, 'Service-Flags'),
      deps: [HarnessFacade, LoggerService, SERVICE_HARNESS_KEY],
    },
    {
      provide: CS_HARNESS_KEY,
      useFactory: (): string => window[ENV_VARS.HARNESS_KEY],
    },
    {
      provide: SERVICE_HARNESS_KEY,
      useFactory: (): string => window[ENV_VARS.SERVICE_HARNESS_KEY],
    },
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
