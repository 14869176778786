import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ControlPageFacade, ModalFacade } from '@ra-state';
import { LoggerService } from '@servicesV2/logger.service';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { AuthenticationService } from '../auth.service';

@Component({
  selector: 'app-auth-callback',
  template: '<div *ngIf="(user$ | async) && (afterAll$ | async)"></div>',
})
export class AuthCallbackComponent {
  user$ = this.authService.user$.pipe(catchError((error: unknown) => this.checkMergedAccount$(error)));

  checkMergedAccount$(error): Observable<never> {
    this.controlPageFacade.routeStoreState$.subscribe((params: any) => {
      if (
        params.queryParams.error === 'access_denied' &&
        params.queryParams.error_description.startsWith('ACCOUNT_MERGED')
      ) {
        this.modalFacade.openAlertDialog({
          header: 'Account merged. Please login again.',
          body: 'Your MyRockwell account has been merged into your SSO account. Please login again. If you use MFA to access FactoryTalk Remote Access or FactoryTalk Optix, you also need to remove your previous codes from your mobile phone and set up a new MFA code.',
          onConfirm: () => this.router.navigate(['/sign-in']),
        });
      }
    });
    return throwError(() => error);
  }

  afterAll$ = this.user$.pipe(
    tap((userProfile) => {
      this.controlPageFacade.setUserLoggedIn(userProfile);
      this.logger.log('Auth callback app init completed');
    }),
  );

  constructor(
    private authService: AuthenticationService,
    private controlPageFacade: ControlPageFacade,
    private logger: LoggerService,
    private modalFacade: ModalFacade,
    private router: Router,
  ) {}
}
