<span raUiTooltip="{{ Disabled ? AllocateToolTip : null }}" [tooltipConfig]="AllocateTooltipConfig">
  <ra-ui-outlined-button
    *ngIf="Status === 'Active' || TrialLinkStatus === 'redeemed'"
    label="Allocate"
    (click)="btnClickedHandler()"
    [disabled]="Disabled"
  ></ra-ui-outlined-button>
</span>
<div
  class="status-icon"
  *ngIf="TrialLinkStatus === TRIAL_LINK_STATUS.TRIALEXPIRED || Status === ENTITLEMENT_STATUS.COMPLETED"
>
  <i class="ra-icon-ide-md-color-unprovisioned"></i>
  <span>Expired</span>
</div>
<div class="cancelled-status" *ngIf="Status === ENTITLEMENT_STATUS.CANCELLED">
  <span
    class="status-icon"
    [raUiTooltip]="'This entitlement has been cancelled by an administrator.'"
    [tooltipConfig]="tooltipConfig"
  >
    <i class="ra-icon-ide-md-color-unprovisioned"></i>
    Cancelled
  </span>
</div>
