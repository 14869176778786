import { Component } from '@angular/core';
import { ControlPageFacade } from '@ra-state';
import { AppInsightsMonitoringService } from '@servicesV2/app-insights-monitoring.service';
import { LoggerService } from '@servicesV2/logger.service';
import { filter, skipWhile, switchMap, take, tap } from 'rxjs';
import { AuthenticationService } from './v2/auth/auth.service';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  isLoading$ = this.controlPageFacade.isLoading$;

  extendSession$ = this.controlPageFacade.extendSession$.pipe(
    filter((value) => value),
    tap(() => {
      this.authService.checkAuth0Session();
      this.controlPageFacade.setExtendSession(false);
    }),
  );
  logoutSession$ = this.controlPageFacade.logoutSession$.pipe(
    filter((value) => value),
    tap(() => {
      this.authService.logout();
      this.controlPageFacade.setLogoutSession(false);
    }),
  );

  constructor(
    private readonly authService: AuthenticationService,
    private readonly controlPageFacade: ControlPageFacade,
    private readonly logger: LoggerService,
    private appInsightsMonitoringService: AppInsightsMonitoringService,
  ) {
    this.appInsightsMonitoringService.startAppInsightMonitoring();
    this.logger = this.logger.withContext('AppComponent');
  }

  initApp$ = this.authService.isAuthenticated$.pipe(
    skipWhile((it) => it.valueOf() === false),
    // this.authService.user$ is triggred multiple times for each /authorize call
    // take(1) is to stop the subscription after emitting once
    switchMap(() => this.authService.user$.pipe(take(1))),
    tap((userProfile) => {
      this.controlPageFacade.setUserLoggedIn(userProfile);
    }),
  );
}
