import { ApiError } from '@core/api-error';
import { createAction, props } from '@ngrx/store';
import { IEulaConfig } from '@ra-state';
import { NavItem } from '@rockwell-automation-inc/common-utils';
import { UserProfile } from '@rockwell-automation-inc/service';
import { ApplicationCard, ICatalogInfo, LoginError, RouteState, Version } from '../lemans-app.model';

export const loading = createAction('[CONTROL_PAGE] Set Loading', props<{ value: boolean }>());
export const setEulaStatus = createAction('[CONTROL_PAGE] Set EULA', props<{ value: boolean }>());
export const setUserNotFound = createAction('[CONTROL_PAGE] Set UserNotFound', props<{ value: boolean }>());
export const getApplications = createAction('[APPLICATIONS] Get Applications', props<{ payload: ApplicationCard[] }>());
export const getEnabledApplications = createAction(
  '[APPLICATIONS] Get Enabled Applications',
  props<{ payload: ApplicationCard[] }>(),
);
export const acceptEULA = createAction('[EULA] Accept EULA', props<{ value?: string }>());
export const acceptEULASuccess = createAction('[EULA] Accept EULA Success');
export const startAppInitializer = createAction('[App] Starting App');
export const baseURLSuccess = createAction('[App] Base URL Set', props<{ value: boolean }>());
export const FinishAppInitializer = createAction('[App] Rockwell-automation-inc library loaded');

export const navigateTo = createAction('[Navigate] Navigate To Route', props<{ path: string }>());
export const navigateToError = createAction('[Error] Navigate To Error', props<{ payload: ApiError }>());
export const userLoginComplete = createAction('[CONTROL_PAGE] UserLoggedIn', props<{ payload: UserProfile }>());
export const userLoginFailed = createAction('[CONTROL_PAGE] UserLoginFailed', props<{ payload: LoginError }>());

export const setErrorContext = createAction('[Error] Set Error Context', props<{ payload: ApiError }>());

export const getEulaConfig = createAction('[EULA] Get EULA Config');
export const setEulaConfigSuccess = createAction('[EULA] Get EULA Config Success', props<{ payload: IEulaConfig }>());

export const getEulaContent = createAction('[EULA] Get EULA Content', props<{ url: string }>());
export const setEulaContentSuccess = createAction('[EULA] Get EULA Content Success', props<{ value: string }>());

export const setVersions = createAction('[APIVersion] Set Version', props<{ value: Version }>());

export const setProductCatalogSuccess = createAction(
  '[CATALOG] Get Catalog Info Success',
  props<{ payload: ICatalogInfo[] }>(),
);

export const extendSession = createAction('[SESSION_TIMEOUT] extendSession');
export const setExtendSession = createAction('[SESSION_TIMEOUT] Set Extend Session', props<{ value: boolean }>());
export const logoutSession = createAction('[SESSION_TIMEOUT] logoutSession');
export const setLogoutSession = createAction('[SESSION_TIMEOUT] set Logout Session', props<{ value: boolean }>());
export const recordUserActivity = createAction('[SESSION_TIMEOUT] Record Active Activity');
export const setUserActive = createAction('[SESSION_TIMEOUT] Set Active User', props<{ value: boolean }>());
export const checkUserSession = createAction('[SESSION_TIMEOUT] Check User Session');
export const setSessionTimedOut = createAction('[SESSION_TIMEOUT] Set Session Timed Out', props<{ value: boolean }>());
export const resetSessionTimedOut = createAction('[SESSION_TIMEOUT] Reset Session Timed Out');

/// TODO: this should be removed
/// instead
/// - user logs in, user prefs are loaded
///   - control page has an effect that inspects route state and manages flow
///     - handles EULA and return
///   - then handles individual flow - invitation accept/dashboard/join org/trial redemption etc
export const setAppIntialRoute = createAction(
  '[INIT ROUTE] Set App Intial Route Success',
  props<{ payload: RouteState }>(),
);

export const setHelpMenuToOpen = createAction('[HELP_MENU] Set Help Menu To Open', props<{ value: NavItem }>());
