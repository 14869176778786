<ng-content *ngIf="userData$ | async"></ng-content>
<div class="deallocate-btn-container" *ngIf="Status === ENTITLEMENT_STATUS.ACTIVE">
  <span class="status-icon">
    <i class="ra-icon-ide-md-color-check"></i>
    <span>Active</span>
  </span>

  <ra-ui-text-button
    *ngIf="Displayed && showDeallocateBtn"
    label="Deallocate"
    (click)="btnClickedHandler()"
  ></ra-ui-text-button>
</div>
<div class="deallocate-btn-container" *ngIf="Status === ENTITLEMENT_STATUS.PENDING">
  <!-- TODO: Add actual activation date for pending status -->
  <span
    class="status-icon"
    [raUiTooltip]="'This entitlement is scheduled to become active on Dec DD, YYYY.'"
    [tooltipConfig]="tooltipConfig"
  >
    <i class="ra-icon-ide-md-auto-refresh"></i>
    <span>Pending</span>
  </span>
  <ra-ui-text-button *ngIf="showDeallocateBtn" label="Deallocate" (click)="btnClickedHandler()"></ra-ui-text-button>
</div>
<div class="status-icon" *ngIf="Status === ENTITLEMENT_STATUS.COMPLETED">
  <i class="ra-icon-ide-md-color-unprovisioned cancelled-icon"></i>
  <span class="grey-out">Expired</span>
</div>

<div class="cancelled-status" *ngIf="Status === ENTITLEMENT_STATUS.CANCELLED">
  <span
    class="status-icon"
    [raUiTooltip]="'This entitlement has been cancelled by an administrator.'"
    [tooltipConfig]="tooltipConfig"
  >
    <i class="ra-icon-ide-md-color-unprovisioned cancelled-icon"></i>
    <span class="grey-out">Cancelled</span>
  </span>
</div>
