import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { CommonConstants } from '@core/common-constants';
import { EntitlementStatus } from '@ra-state';
import { BADGE_STATUS_COLORS, BadgePosition, BadgeSize } from '@ra-web-tech-ui-toolkit/components';

@Component({
  styleUrls: ['./ag-service-details.component.scss'],
  templateUrl: './ag-service-details.component.html',
})
export class AgServiceDetailsComponent implements ICellRendererAngularComp {
  BADGE_STATUS_COLORS = BADGE_STATUS_COLORS;
  BadgePosition = BadgePosition;
  BadgeSize = BadgeSize;
  params: ICellRendererParams & { userEntitlement: boolean };
  highlightNewItems: boolean;
  toggle: boolean = false;
  greyOutText: boolean = false;

  agInit(params: ICellRendererParams & { userEntitlement: boolean }): void {
    this.params = params;
    this.highlightNewItems = this.params['highlightNewItems'] || false;
    this.greyOutText =
      params.data?.status === EntitlementStatus.CANCELLED || params.data?.status === EntitlementStatus.COMPLETED;
  }

  // Optional - Return true if you want to manage a cell refresh yourself, otherwise return false.
  // If you return false, the grid will remove the component from the DOM and create  a new component in its place
  // with the new values.
  refresh(): boolean {
    return false;
  }

  checkIsNew(): boolean {
    if (!this.highlightNewItems) {
      return false;
    }
    const currentDate = new Date();
    const data = this.params.data;
    const effectiveDate = new Date(data.effectiveDate);
    const daysSinceEffective = (currentDate.getTime() - effectiveDate.getTime()) / (3600 * 24 * 1000);
    return daysSinceEffective <= CommonConstants.entitlementNewPeriodInDays && !data.consumed;
  }

  toggleDetails(): void {
    this.toggle = !this.toggle;
  }
}
