import { createAction, props } from '@ngrx/store';
import { FeatureFlag, FeatureFlagTarget, FeatureFlagUpdate, FlagName, HarnessIdentifier } from '@ra-state';
import { FlagScope } from '@servicesV2/harness.service';

export const initializeTarget = createAction(
  '[Feature-Flag] Initialize Target',
  props<{ scope?: FlagScope; payload: FeatureFlagTarget }>(),
);

export const closeTarget = createAction(
  '[Feature-Flag] Close Target',
  props<{ scope: FlagScope; harnessIdentifier: HarnessIdentifier }>(),
);

export const setFeatureFlags = createAction(
  '[Feature-Flag] Set Feature Flags',
  props<{ scope: FlagScope; flags: FeatureFlag }>(),
);

export const updateFeatureFlag = createAction(
  '[Feature-Flag] Update Feature Flag',
  props<{
    scope: FlagScope;
    payload: FeatureFlagUpdate;
  }>(),
);

export const deleteFeatureFlag = createAction(
  '[Feature-Flag] Delete Feature Flag',
  props<{ scope: FlagScope; payload: FlagName }>(),
);

export const emptyHarnessAction = createAction('[EMTPY] Empty Action');
