import { createSelector } from '@ngrx/store';
import { AppState, FeatureFlagStoreState } from '../lemans-app.model';

export const selectFeatureFlagsFeature = (state: AppState): FeatureFlagStoreState => {
  return state.featureFlags;
};

export const selectFlags = createSelector(
  selectFeatureFlagsFeature,
  (state: FeatureFlagStoreState) => state['Common-Service'],
);
export const selectTileMaintenanceWindowFlags = createSelector(
  selectFeatureFlagsFeature,
  (state: FeatureFlagStoreState) => state['Service-Flags'],
);
