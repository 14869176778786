import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HarnessService } from '@servicesV2/harness.service';
import { map, Observable } from 'rxjs';
import { closeTarget, emptyHarnessAction, initializeTarget } from './feature-flags.actions';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsEffects {
  // initializeApp$ = createEffect((): Observable<any> => {
  //   return this.actions$.pipe(
  //     ofType(startAppInitializer),
  //     map(() => {
  //       const action = intializeTarget({
  //         payload: {
  //           identifier: 'common-service-portal',
  //           attributes: {
  //             Type: 'Application',
  //           },
  //         },
  //       });
  //       return action;
  //     }),
  //   );
  // });
  intializeTarget$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(initializeTarget),
      map((payload) => {
        this.harnessService.initializeTarget(payload.payload);
        return emptyHarnessAction();
      }),
    );
  });

  closeTarget$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(closeTarget),
      map((payload) => {
        this.harnessService.closeChangeFeed(payload.harnessIdentifier);
        return emptyHarnessAction();
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly harnessService: HarnessService,
  ) {}
}
