import { createReducer, on } from '@ngrx/store';
import { FeatureFlagStoreState } from '../lemans-app.model';
import { deleteFeatureFlag, setFeatureFlags, updateFeatureFlag } from './feature-flags.actions';

export const featureFlagsInitialState: FeatureFlagStoreState = {};

export const featureFlagsReducer = createReducer(
  featureFlagsInitialState,
  on(setFeatureFlags, (state: FeatureFlagStoreState, { scope, flags }): FeatureFlagStoreState => {
    return { ...state, [scope]: flags };
  }),
  on(updateFeatureFlag, (state: FeatureFlagStoreState, { scope, payload: harnessUpdate }): FeatureFlagStoreState => {
    const flags = { ...state[scope] };
    flags[harnessUpdate.flag] = harnessUpdate.value;
    return { ...state, [scope]: flags };
  }),
  on(deleteFeatureFlag, (state: FeatureFlagStoreState, { scope, payload: flag }): FeatureFlagStoreState => {
    const flags = { ...state[scope] };
    delete flags[flag];
    return { ...state, [scope]: flags };
  }),
);
