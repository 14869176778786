import { createSelector } from '@ngrx/store';
import { AppState, PageControl } from '../lemans-app.model';

export const selectPageControlFeature = (state: AppState): PageControl => state.controlPage;
export const selectLoadingState = createSelector(selectPageControlFeature, (state: PageControl) => state.isLoading > 0);
export const selectEulaState = createSelector(selectPageControlFeature, (state: PageControl) => state.isEulaAccepted);
export const selectUserNotFoundState = createSelector(
  selectPageControlFeature,
  (state: PageControl) => state.isUserNotFound,
);
/* eslint-disable @ngrx/prefix-selectors-with-select */
export const getApps = createSelector(selectPageControlFeature, (state: PageControl) => state.applications);
export const selectAppsMap = createSelector(selectPageControlFeature, (state: PageControl) => state.appsMap);
export const getEnabledApps = createSelector(
  selectPageControlFeature,
  (state: PageControl) => state.enabledApplications,
);
export const selectAppsDisplayNameMap = createSelector(selectPageControlFeature, (state: PageControl) =>
  Object.assign({}, ...state.applications.map((app) => ({ [app.appId]: app.appName }))),
);
export const isBaseURLSet = createSelector(selectPageControlFeature, (state: PageControl) => state.isBaseURLSet);
/* eslint-enable @ngrx/prefix-selectors-with-select */
export const selectEulaContent = createSelector(selectPageControlFeature, (state: PageControl) => state.eulaContent);
export const selectEulaConfig = createSelector(selectPageControlFeature, (state: PageControl) => state.eulaConfig);
export const selectApiError = createSelector(selectPageControlFeature, (state: PageControl) => state.apiError);
export const selectCatalogInfo = createSelector(selectPageControlFeature, (state: PageControl) => state.catalogInfo);
export const selectVersions = createSelector(selectPageControlFeature, (state: PageControl) => state.versions);
export const selectIsUserActive = createSelector(selectPageControlFeature, (state: PageControl) => state.isUserActive);
export const selectSessionTimedOut = createSelector(
  selectPageControlFeature,
  (state: PageControl) => state.sessionTimedOut,
);
export const selectExtendSession = createSelector(
  selectPageControlFeature,
  (state: PageControl) => state.extendSession,
);
export const selectLogoutSession = createSelector(
  selectPageControlFeature,
  (state: PageControl) => state.logoutSession,
);
export const selectHelpMenuToOpen = createSelector(
  selectPageControlFeature,
  (state: PageControl) => state.helpMenuToOpen,
);
export const selectLoggedInUserProfile = createSelector(
  selectPageControlFeature,
  (state: PageControl) => state.loggedInUserProfile,
);

export const selectRouteState = createSelector(selectPageControlFeature, (state: PageControl) => state.routeState);

export const selectShowArchiveBanner = createSelector(
  selectPageControlFeature,
  (state: PageControl) => state.showArchiveBanner,
);

export const selectGetCurrentRouteState = createSelector(
  (state) => state,
  (state: AppState) => state.routerState.state,
);
