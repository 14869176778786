import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { accessRequestReducer } from './access-requests';
import { controlPageReducer } from './control-page';
import { featureFlagsReducer } from './feature-flags';
import { invitationsReducer } from './invitation';
import { AppState } from './lemans-app.model';
import { signalrReducer } from './signalR';
import { snackbarDataReducer } from './snackbar/snackbar.reducer';
import { trialsReducer } from './trials';
import { setUserData } from './user-data';

export const reducers: ActionReducerMap<AppState> = {
  controlPage: controlPageReducer,
  signalR: signalrReducer,
  userData: setUserData,
  featureFlags: featureFlagsReducer,
  snackBarData: snackbarDataReducer,
  trials: trialsReducer,
  invitation: invitationsReducer,
  accessRequests: accessRequestReducer,
  routerState: routerReducer,
};
